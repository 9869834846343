<template>
  <div class='box-panel'>
    <el-form ref='elForm' :model='formData' :rules='rules' label-width='120px' label-suffix='：'>
      <el-card>
        <div slot='header' class='clearfix'>
          <div style='display: flex;flex-direction: row;justify-content: space-between'>
            <span class='is-label'>基本信息（{{ platformInfo.name }}）</span>
            <div style='display: flex;flex-direction: row;align-items: center;gap: 5px'>
              <!--              <el-image style='width: 20px;height: 20px' fit='contain'-->
              <!--                        :src='platformInfo.logo_url'-->
              <!--                        :alt='platformInfo.name' v-if='platformInfo.logo_url' />-->
              <div style='line-height: 20px;'>
                <el-link v-if="userPermissions.indexOf('platform_setting')>-1" type='primary'
                         icon='el-icon-setting' @click='jumpToSettingPage(platformInfo)'></el-link>
              </div>
            </div>
          </div>
        </div>
        <div class='base-box'>
          <div v-if="formData.id&&userPermissions.indexOf('plc_item_edit')>-1"
               style='line-height: 30px;display: flex;flex-direction: row;gap: 10px;justify-content: flex-end;border-bottom: #f2f2f2 1px solid;margin-bottom: 10px'>
            <el-link style=' padding: 3px 0' type='primary' v-if='!editBase'
                     @click='editBase = true'><i
              class='el-icon-edit-outline'></i>修改
            </el-link>
            <div v-else>
              <el-link style='padding: 3px 0;margin-right: 5px' type='default' @click='editBase=false'><i
                class='el-icon-close'></i>取消
              </el-link> &nbsp;&nbsp;
              <el-link style=' padding: 3px 0;margin-right: 5px' type='success' @click='saveBaseBtn'><i
                class='el-icon-finished'></i>保存
              </el-link>
            </div>

          </div>
          <div style='margin-bottom: 8px;padding: 10px 0;display: flex;flex-direction: row;gap: 10px;'>
            <div class='box-kol'>
              <el-form-item label='红人' prop='nickname' :label-width='label_width'>
                <div style='display: flex;flex-direction: row;justify-content: flex-start;gap: 5px'>
                  <span v-if='!editBase'>{{ formData.nickname }} </span>
                  <div v-else style='width: 100%;display: flex;flex-direction: row;gap: 5px'>
                    <ArtistSearch style='width: 90%;' :name.sync='formData.nickname' ref='refArtistSearch'
                                  @handleSelect='selectArtist' @clearSelect='selectArtist'></ArtistSearch>
                    <el-link icon='el-icon-close' @click='clearArtist'></el-link>
                  </div>

                </div>
              </el-form-item>
              <el-form-item label='标签' :label-width='label_width'>
                <el-radio-group v-model='formData.tag' style='width: 280px'>
                  <el-radio :label='tag.val' v-for='(tag,idx) in tagMap' :key='idx'
                            :disabled='!editBase'>
                    {{ tag.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label='刊例月份' :label-width='label_width' prop='ym'>
                <template slot='label'>
                  <span style='color: #E6A23C;font-weight: 800'>刊例月份</span>
                </template>
                <span>{{ formData.year }} 年 {{ formData.month }} 月</span>
              </el-form-item>
              <el-form-item label='平台' :label-width='label_width'
                            v-if='fieldRange.indexOf("platform")>-1' prop='platform'>
                <div>
                  <span
                    v-if='!editBase'>{{ formData.platform }}</span>
                  <PlatformNameSelect :style='`width: ${input_width}`' :multiple='false' v-else
                                      v-model='formData.platform'
                                      @change='handleChangePlatform' />
                </div>
              </el-form-item>
              <el-form-item label='KOL简介' :label-width='label_width' prop='intro'>
                <span v-if='!editBase'>{{ formData.intro }} </span>
                <el-input v-else v-model='formData.intro' type='textarea' :rows='12' placeholder='请输入KOL简介'
                          show-word-limit
                          maxlength='500' disabled />
              </el-form-item>

            </div>
            <div class='box-kol' v-if='formData.artist_id' style='width: 600px'>
              <div style='color: #fe346e;display: flex;flex-direction: row;gap: 5px;justify-content: space-between'>
                <el-button type='text' icon='el-icon-refresh'
                           @click='getKolPlatformInfo'
                           class='link-btn' :loading='loading' :disabled='loading'
                           :title='`同步红人最新平台信息`' v-if='editBase'>同步
                </el-button>

                <div>
                  <i class='el-icon-info'></i> 数据同步自《红人平台信息》
                  <el-button type='text' icon='el-icon-edit-outline' v-if='formData.artist_id'
                             @click='goToArtistDetail(formData.artist_id)' class='link-btn'
                             :title='`编辑红人平台信息`'></el-button>
                </div>
              </div>
              <el-form-item label='头像' :label-width='label_width'>
                <el-avatar :size='60' :src='formData.cover_pc_url' alt='头像' v-if='formData.cover_pc_url'>
                  <!--                  <img src='https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png' />-->
                </el-avatar>
              </el-form-item>
              <el-form-item label='平台ID' :label-width='label_width' prop='account_id'>
                {{ formData.account_id }}
                <!--                <span v-if='!editBase'>{{ formData.account_id }} </span>-->
                <!--                <el-input v-else v-model='formData.account_id' show-word-limit maxlength='100' disabled />-->
              </el-form-item>
              <el-form-item label='所在地区' prop='city' :label-width='label_width'>
                {{ formData.city }}
                <!--                <span v-if='!editBase'>{{ formData.city }} </span>-->
                <!--                <el-input v-else v-model='formData.city' show-word-limit maxlength='120' disabled />-->
              </el-form-item>
              <el-form-item label='经纪人' :label-width='label_width' prop='agent_name'>
                {{ formData.agent_name }}
                <!--                <span v-if='!editBase'>{{ formData.agent_name }} </span>-->
                <!--                <el-input v-else v-model='formData.agent_name' show-word-limit maxlength='100' disabled />-->
              </el-form-item>
              <el-form-item label='主页链接' prop='home_page_link' :label-width='label_width'>
                {{ formData.home_page_link }}
                <!--                <span v-if='!editBase'>{{ formData.home_page_link }} </span>-->
                <!--                <el-input v-else v-model='formData.home_page_link' placeholder='主页链接' disabled>-->
                <!--                </el-input>-->
                <el-link v-if='!editBase' type='primary' @click='openUrl(formData.home_page_link)'
                         icon='el-icon-link'></el-link>
              </el-form-item>
              <el-form-item label='红人肤质' :label-width='label_width'>
                {{ formData.skin_type }}
                <!--                <span v-if='!editBase'>{{ formData.skin_type }} </span>-->
                <!--                <el-input v-else type='text' v-model='formData.skin_type'-->
                <!--                          disabled-->
                <!--                          clearable />-->
              </el-form-item>
              <el-form-item label='红人标签' :label-width='label_width'>
                {{ formData.kol_label }}
                <!--                <span v-if='!editBase'>{{ formData.kol_label }} </span>-->
                <!--                <el-input v-else type='text' v-model='formData.kol_label'-->
                <!--                          disabled-->
                <!--                          clearable />-->
              </el-form-item>
            </div>
          </div>
          <div ref='BaseForm' class='box-info' style='position: relative;'>
            <span v-if='editBase&&platformType === "LIVE"' class='sync-last-month' @click='handleSyncLastMonth'>
              <i class='el-icon-refresh' />点击同步上月数据
            </span>
            <el-form-item label='最近广告排期' :label-width='label_width' prop='last_release_date'>
              <template slot='label'>
                <span>广告排期
                  <el-tooltip class='item' effect='dark' content='最近广告排期：品宣商务类型的档期最后的排期'
                              placement='right'>
                    <i class='el-icon-question'></i>
                  </el-tooltip>
                </span>
              </template>
              <span v-if='!editBase'>{{ $utils.parseTime(formData.last_release_date, '{y}年{m}月{d}日') }}</span>
              <el-date-picker
                v-else
                v-model='formData.last_release_date'
                type='date'
                format='yyyy年MM月dd日'
                value-format='yyyy-MM-dd'
                placeholder='最近广告排期' :disabled='!editBase' clearable>
              </el-date-picker>
            </el-form-item>

            <el-form-item label='平台' :label-width='label_width'
                          v-if='fieldRange.indexOf("platforms")>-1' prop='platforms'>
              <div>
                <span v-if='!editBase'>{{ formData.platforms ? formData.platforms.join('、') : '' }}</span>
                <PlatformNameSelect :style='`width: ${input_width}`' :multiple='true' v-else
                                    v-model='formData.platforms'></PlatformNameSelect>
              </div>
            </el-form-item>
            <template v-if='formRenderData && formRenderData.length>0'>
              <div v-for='(f,index) in formRenderData' :key='index' style='width: 50%;'>
                <el-form-item :label='calcColName(f.field,f.title)' v-if='f.is_auto' :label-width='label_width'
                              :prop='f.field'>
                  <span v-if='!editBase'>{{ formData[f.field] || '-' }}</span>
                  <template v-else>
                    <div class='fl-row'>
                      <el-input type='number' :style='`width: ${input_width}`' v-if='f.type==="number"'
                                v-model='formData[f.field]'
                                :placeholder='f.placeholder' clearable></el-input>
                      <el-input :style='`width: ${input_width}`' v-if='f.type==="input"' v-model='formData[f.field]'
                                :placeholder='f.placeholder' clearable></el-input>
                      <el-select :style='`width: ${input_width}`' v-else-if='f.type==="select"' filterable allow-create
                                 v-model='formData[f.field]'
                                 :placeholder='f.placeholder' clearable>
                        <el-option v-for='(option,idx) in f.options'
                                   :value='option.value' :label='option.label' :key='idx'></el-option>
                      </el-select>

                    </div>
                  </template>
                </el-form-item>
              </div>
            </template>
            <el-row>
              <el-col :span='12'>
                <el-form-item label='当月订单数' :label-width='label_width' prop='order_total'>
                  <template slot='label'><span>当月订单数</span></template>
                  <div class='flex-start'>
                    <span v-if='!editBase'>{{ formData.order_total }} 单</span>
                    <div v-else>
                      <el-input type='number' v-model='formData.order_total'
                                placeholder='录入确定合作的单数' :disabled='!editBase'
                                :style='`width: ${input_width}`'
                                clearable>
                      </el-input>
                      <el-button type='text' icon='el-icon-refresh'
                                 @click='syncFromAdSystem'
                                 class='link-btn' :loading='loading' :disabled='loading'
                                 :title='`录入确定合作的单数`'></el-button>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span='12'>
                <el-form-item label='下月订单数' :label-width='label_width' prop='next_order_total'>
                  <template slot='label'>
                    <span>下月订单数</span>
                  </template>
                  <span v-if='!editBase'>{{ formData.next_order_total }} 单</span>
                  <el-input v-else type='number' v-model='formData.next_order_total'
                            placeholder='录入确定合作的单数' :disabled='!editBase' :style='`width: ${input_width}`'
                            clearable>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <!--          服务开通情况-->
            <div class='fl-row'>
              <el-form-item label='开通星图' v-if="fieldRange.indexOf('opened_star')>-1" :label-width='label_width'>
                <el-switch v-model='formData.opened_star' active-value='Y' active-text='是'
                           inactive-value='N'
                           inactive-text='否'></el-switch>
              </el-form-item>
              <el-form-item label='开通橱窗' v-if="fieldRange.indexOf('opened_window')>-1" :label-width='label_width'>
                <el-switch v-model='formData.opened_window' active-value='Y' active-text='是'
                           inactive-value='N'
                           inactive-text='否'></el-switch>
              </el-form-item>
              <el-form-item label='品牌合作人' v-if="fieldRange.indexOf('is_partner')>-1" :label-width='label_width'>
                <el-switch v-model='formData.is_partner' active-value='Y' active-text='是'
                           inactive-value='N'
                           inactive-text='否'></el-switch>
              </el-form-item>
              <el-form-item label='开通购物车' v-if="fieldRange.indexOf('opened_cart')>-1" :label-width='label_width'>
                <el-switch v-model='formData.opened_cart' active-value='Y' active-text='是'
                           inactive-value='N'
                           inactive-text='否'></el-switch>
              </el-form-item>
            </div>
            <!--          线下活动-->

            <div v-if="platformType === 'OFFLINE'" class='border-effect'>
              <div style='text-align: left'>
                <span class='sub-title'>线下活动</span>
              </div>
              <div style='padding: 5px'>
                <el-form-item label='全网粉丝' :label-width='label_width' prop='fans_total'>
                  <el-input type='number' v-model='formData.fans_total'
                            placeholder='全网粉丝' style='width: 40%' clearable>
                    <el-button slot='append' icon='el-icon-refresh' :loading='loading'
                               @click='getFansData'
                               title='更新红人粉丝数'></el-button>
                  </el-input>
                </el-form-item>

                <el-form-item label='' :label-width='label_width'>
                  <template slot='label'>
                    <el-link type='primary' @click='initFansState' icon='el-icon-refresh'></el-link>
                  </template>
                  <div v-if='formData.fans_state && formData.fans_state.length>0'
                       style='display: flex;flex-direction: row;gap: 5px;flex-wrap: wrap;justify-content: flex-start'>
                    <div style='width: 40%;' v-for='(item,index) in formData.fans_state' :key='index'>
                      <el-input type='number' placeholder='请输入平台粉丝数' v-model='item.fans_count'
                                clearable>
                        <template slot='prepend'>{{ item.platform_name }}</template>
                      </el-input>
                    </div>
                  </div>
                </el-form-item>
              </div>
            </div>


            <!--          订单相关-->
            <div v-else-if="platformType === 'ONLINE'" class='border-effect'>
              <div style='text-align: left'>
                <span class='sub-title'>线上平台</span>
              </div>
              <el-row>
                <el-col :span='12'>
                  <el-form-item label='现粉丝数' :label-width='label_width' prop='fans_total'>
                    <div class='flex-start'>
                      <span v-if='!editBase'>{{ formData.fans_total }} 个</span>
                      <div v-else>
                        <el-input type='number' v-model='formData.fans_total'
                                  placeholder='同步自红人涨粉数据（单位：个）' :style='`width: ${input_width}`'
                                  :disabled='!editBase'
                                  clearable>
                        </el-input>
                        <el-button type='text' icon='el-icon-refresh'
                                   @click='getCurrentMonthData'
                                   class='link-btn' :loading='loading' :disabled='loading'
                                   :title='`同步红人粉丝数`'></el-button>
                      </div>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span='12'>
                  <el-form-item label='预估粉丝量' :label-width='label_width' prop='pre_fans_total'>
                    <span v-if='!editBase'>{{ formData.pre_fans_total }} 个</span>
                    <el-input v-else type='number' v-model='formData.pre_fans_total'
                              placeholder='下月底预估粉丝量，必填（单位：个）'
                              :disabled='!editBase'
                              clearable />
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <div v-else>
              <el-form-item label='粉丝数' :label-width='label_width' prop='fans_total'>
                <el-input v-if='editBase' type='number' v-model='formData.fans_total'
                          placeholder='单位：个 -> 万，例如：”1000000“ -> ”100“' style='width: 40%' clearable>
                  <el-button slot='append' icon='el-icon-refresh' :loading='loading'
                             @click='getFansData'
                             title='更新红人粉丝数'></el-button>
                </el-input>
                <span style='color: red;font-size: 0.9em;'>
                  {{ $utils.numberFormat(formData.fans_total / 10000, 2, '.', ',') }} 万</span>
              </el-form-item>
            </div>

            <!--          商务案例 -->
            <el-row>
              <el-col v-if="fieldRange.indexOf('bs_case')>-1">
                <el-form-item :label="calcColName('bs_case','商务案例')" :label-width='label_width' prop='bs_case'>
                  <div class='rich-txt' v-if='!editBase' v-html='formData.bs_case' style='min-height: 100px'></div>
                  <ApeEditor v-else :toolbar="'undo redo paste | bold italic underline strikethrough | fontselect fontsizeselect |  forecolor backcolor | ' +
          'ltr rtl | alignleft aligncenter alignright alignjustify ' +
          '  | removeformat anchor hr |' +
          ' code preview fullscreen'" :init-html='formData.bs_case'
                             @handleTinymceInput='handleTinymceInput'></ApeEditor>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
        <!--        刊例提报报价信息-->
        <div class='price-box' style='margin-top: 10px;' v-if='showPriceBox'>
          <div style='margin-bottom: 8px;border-bottom: #f2f2f2 1px solid;padding: 10px'>
            <el-row>
              <el-col :span='8'>
                <span style='font-weight: 800;font-size: 1.1em'>刊例提报报价信息</span>
              </el-col>
              <el-col :span='12'>
                <el-link type='primary' @click='refreshPlatformQuote' icon='el-icon-refresh'>报价类型
                </el-link>
                <el-link :disabled='!formData.platform_code' type='warning' icon='el-icon-document-copy'
                         @click='copyLastPlatformQuote'
                         style='margin-left: 20px'>
                  不调价
                </el-link>
                <el-link type='danger' @click='clearQuote' icon='el-icon-refresh-right'
                         style='margin-left: 20px'>
                  调价
                </el-link>
              </el-col>
              <el-col :span='4' v-if="!!formData.id&&userPermissions.indexOf('plc_edit_price')>-1">
                <el-button style='float: right; padding: 3px 0' type='text' @click='editPrice = true'
                           v-if='!editPrice'>
                  <i class='el-icon-edit-outline'></i>
                  {{ formData.status === 2 ? '申请改价' : '修改' }}
                </el-button>
                <el-button style='float: right; padding: 3px 0' type='text' @click='saveEdit' v-else><i
                  class='el-icon-finished'></i>保存
                </el-button>
              </el-col>
            </el-row>
          </div>
          <div style='padding: 5px'>
            <!--当月价格-->
            <AdjustPricePanel
              :show-multi-price='priceTypeMap.multi'
              :show-single-price='priceTypeMap.single'
              :quote-info.sync='formData.current_quote'
              :title='`当月（${currentMonth}）价格`'
              :edit-price='editPrice'
              :platform-type='platformType' />
            <br />
            <!--下月预估-->
            <AdjustPricePanel
              :show-multi-price='priceTypeMap.multi'
              :show-single-price='priceTypeMap.single'
              :quote-info.sync='formData.next_quote'
              :title='`下月（${year}年${month}月）预估`'
              :edit-price='editPrice' :platform-type='platformType' />
          </div>
        </div>
        <div class='submit-btn-row' v-if='!formData.id'>
          <el-button round size='medium' type='primary' @click='handleConfirm' class='submit-btn'
                     :disabled='loadingSb'
                     :loading='loadingSb' v-if="userPermissions.indexOf('plc_adjust')>-1">
            <i class='el-icon-check'></i> &nbsp;&nbsp;保存 - 刊例调整
          </el-button>
        </div>
      </el-card>
    </el-form>
    <br />
    <!--        刊例调整说明组件：：数据来自文章管理-->
    <PlcNotice v-if="userPermissions.indexOf('plc_notice')>-1" />

    <!--    红人匹配   -->
    <ArtistMatch :visible.sync='dialogArtistMatchVisible' :id='formData.artist_id' />

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ArtistSearchCustom from '@/components/artist/ArtistSearchCustom'
import PlatformSelect from '@/pages/platform/components/PlatformSelect'
import ArtistMatch from '@/pages/setting/artist/ArtistMatch'
import PlcNotice from '../PlcNotice'
import EditableCell from '@/components/EditableCell'
import AdjustPricePanel from '@/pages/publication/adjust/AdjustPricePanel'
import { deepClone } from '@/utils'
import ApeEditor from '@/components/ApeEditor'
import { html2json } from 'html2json'
import plcMap from '@/json/plc.json'
import PlatformNameSelect from '@/pages/publication/adjust/PlatformNameSelect'
import ArtistSearch from '@/components/artist/ArtistSearch'
import PlcSet from '@/pages/platform/PlcSet'
import PlcSetting from '@/pages/publication/adjust/PlcSetting'


export default {
  name: 'CreateEdit',
  components: {
    PlcSetting,
    PlcSet,
    ArtistSearch,
    PlatformNameSelect,
    AdjustPricePanel,
    EditableCell,
    ArtistMatch,
    ArtistSearchCustom, PlatformSelect, PlcNotice, ApeEditor
  },
  props: {
    // id: {
    //   type: [Number, String],
    //   require: false
    // },
    year: {
      type: [Number, String],
      require: true
    },
    month: {
      type: [Number, String],
      require: true
    },
    platformInfo: {
      type: [Object],
      require: true
    },
    //配置信息
    setting: {
      type: [Object],
      require: true
    },
    editFormData: {
      type: Object,
      default() {
        return {}
      }
    },
    pid: {
      type: [Number, String],
      require: false
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
    currentMonth() {
      let date = new Date(this.year, this.month - 1)
      date.setMonth(date.getMonth() - 1)
      return date.getFullYear() + '年' + (date.getMonth() + 1) + '月'
    },
    platformType() {
      return this.setting.type || 'ONLINE'
    },
    //报价方式：品类报价（1：n）；类目报价
    priceTypeMap() {
      let customPriceMap = plcMap.customPriceMap

      return customPriceMap[this.platformType] || {
        'multi': true,
        'single': true
      }
    },
    //可查看的字段范围：不同平台配置不同的字段显示
    fieldRange() {
      let common_fields = this.setting.common_fields || []
      let extra_fields = this.setting.extra_fields || []
      return [...common_fields, ...extra_fields]
    },
    // platformQuoteArr() {
    //   return this.setting.platform_quote || []
    // },
    columnsData() {
      return this.setting.columns_data | []
    },
    plcMap() {
      return plcMap
    },
    typeMap() {
      let map = {}
      plcMap.typeMap.forEach((item) => {
        map[item.val] = item.title
      })
      return map
    },
    showPriceBox() {
      //直播和日播不显示 报价信息（展示仅需展示关联平台的报价即可）
      return true
    },
    //表单内容
    formRenderData() {
      let cols = []
      plcMap.common_cols.forEach((f) => {
        //&& this.fieldRange[f.field]
        if (f && f.is_auto && this.fieldRange.indexOf(f.field) > -1) {
          cols.push(f)
        }
      })
      return cols
    }
  },
  data() {
    return {
      showPlcSetting: false,
      currInfo: {},//刊例信息（基本）
      id: null,//编辑对象（提报信息）
      loadingSb: false,
      loadingForm: false,
      loadingOrderTotal: false,
      // setting: {},//配置信息
      label_width: '150px',
      input_width: '360px',
      formData: {
        nickname: '',
        artist_id: '',
        dept_id: '',
        dept_name: '',
        platform_code: this.platformInfo.code,
        platform_name: this.platformInfo.name,
        platform: '',
        extra_platform_code: '',
        account_id: '',
        home_page_link: '',
        agent_name: '',
        city: '',
        intro: '',
        bs_case: '',
        year: this.year,
        month: this.month,
        fans_total: null,//当月粉丝数
        order_total: null,//当月订单数
        next_order_total: null,//当月订单数
        last_release_date: null,//最近的广告排期
        pre_fans_total: null,//预计粉丝量
        current_quote: {
          single: [],
          multi: [
            // {kind: '美妆', sessions: '直播单链接', lb_fee: '130000+30%佣金'}
          ]
        },//当月价格
        next_quote: {
          single: [],
          multi: [
            // {kind: '美妆', sessions: '直播单链接', lb_fee: '130000+30%佣金'}
          ]
        },
        zc_total: null,//赞藏数（万）
        lb_time: null,//直播时间
        lb_case: null,//直播案例
        female_fans_ratio: null,//女粉比例
        avg_online: null,//平均在线
        cover: null,
        opened_star: 'N',
        opened_window: 'N',
        is_partner: 'N',
        opened_cart: 'N',
        cover_pc_url: '',
        fans_state: [],//各个平台粉数数
        //案例富文本
        bs_case_rich: '',
        //红人标签
        kol_label: '',
        //直播频次
        lf: '',
        //百应ID/UID
        by_uid: '',
        //直播业绩
        lsp: '',
        //红人肤质
        skin_type: ''
      },
      rules: {
        nickname: [{ required: true, message: '红人必选', trigger: 'change' }],
        account_id: [{ required: true, message: '平台ID必填', trigger: 'change' }],
        home_page_link: [{ required: true, message: '主页链接必填', trigger: 'change' }],
        agent_name: [{ required: true, message: '负责经纪人必填', trigger: 'change' }],
        city: [{ required: true, message: '所在地区必填', trigger: 'change' }],
        // platform_code: [{required: true, message: '平台必选', trigger: 'blur'}],
        zc_total: [{ required: true, message: '请输入赞藏数必填', trigger: 'change' }],
        fans_total: [{ required: true, message: '请输入当月粉丝数', trigger: 'change' }],
        order_total: [{ required: true, message: '请输入当月订单数', trigger: 'change' }],
        next_order_total: [{ required: true, message: '请输入下月订单数', trigger: 'change' }],
        last_release_date: [{ required: true, message: '最近的广告排期', trigger: 'change' }],
        pre_fans_total: [{ required: true, message: '下月底预估粉丝量', trigger: 'change' }],
        intro: [{ required: true, message: '必填', trigger: ['change', 'blur'] }],
        platform: [{ required: true, message: '必填', trigger: ['change', 'blur'] }],
        platforms: [{ required: true, message: '必填', trigger: ['change', 'blur'] }],
        lb_case: [{ required: true, message: '必填', trigger: ['change', 'blur'] }],
        female_fans_ratio: [{ required: true, message: '必填', trigger: ['change', 'blur'] }],
        avg_online: [{ required: true, message: '必填', trigger: ['change', 'blur'] }],
        lsp: [{ required: true, message: '必填', trigger: ['change', 'blur'] }],
        lf: [{ required: true, message: '必填', trigger: ['change', 'blur'] }],
        by_uid: [{ required: true, message: '必填', trigger: ['change', 'blur'] }],
        current_quote: [{ required: true, message: '当月价格', trigger: 'blur' }]
      },
      searchCondition: {},
      platformRange: [],//可选平台
      platforms: [],//平台信息
      loading: false,
      multiTypes: ['DAILY', 'LIVE'],//多平台类型,目前 日播和直播KOL可选多平台
      quoteMap: {},
      dialogArtistMatchVisible: false,//红人匹配
      tagMap: [
        { label: '无', val: '' },
        { label: 'New', val: 'new' }
        // {label: '惠', val: 'benefit'},
        // {label: '荐', val: 'recommend'},
      ],
      editBase: false,//修改基本
      editPrice: false,//修改报价
      //平台视频时长报价（直播刊例，来自所选平台的视频报价结构）
      platformQuoteArr: []
    }
  },
  methods: {
    //编辑器
    handleTinymceInput(val) {
      this.formData.bs_case = val
      this.formData.bs_case_rich = html2json(val)
    },
    add() {
      this.platformQuoteArr = this.setting.platform_quote || []
      this.getPlatforms()
      this.editBase = true
      this.editPrice = true
      this.id = null
      this.clearArtist()
      this.init()

    },
    edit(row) {
      this.getPlatforms()
      this.currInfo = { ...row }
      this.id = row.id

      this.editBase = false
      this.editPrice = false
      this.init()
    },
    async init() {
      if (this.userPermissions.indexOf('plc_save_quote') > -1) {
        this.tagMap = [
          { label: '无', val: '' },
          { label: 'NEW', val: 'new' },
          { label: '惠', val: 'benefit' },
          { label: '荐', val: 'recommend' }
        ]
      }
      // await this.getPlatforms()//平台个数
      // console.log('----- init -----')
      await this.initVal(this.id)
    },
    //初始化-变量
    async initVal(id) {
      // this.$notify.warning(`修改ID：${id}`)
      // let isEdit = !!id// 判断是否有ID，即判断是（有ID）编辑还是（无ID）新增
      if (id) {
        await this.getInfo(id)
      } else {
        this.initForm()//初始化表单
        await this.getPlatformSetting(true)//配置信息
        await this.copyLastInfo()//同步上月信息
      }
    },
    initForm() {
      this.formData = {
        pid: this.pid,
        nickname: '',
        artist_id: '',
        dept_id: '',
        dept_name: '',
        platform_code: this.platformInfo.code,
        platform_name: this.platformInfo.name,
        account_id: '',
        home_page_link: '',
        agent_name: '',
        city: '',
        intro: '',
        bs_case: '',
        year: this.year,
        month: this.month,
        fans_total: null,//当月粉丝数
        order_total: null,//当月订单数
        next_order_total: null,//当月订单数
        last_release_date: null,//最近的广告排期
        pre_fans_total: null,//预计粉丝量
        current_quote: {
          single: [],
          multi: [
            // {kind: '美妆', sessions: '直播单链接', lb_fee: '130000+30%佣金'}
          ]
        },//当月价格
        next_quote: {
          single: [],
          multi: [
            // {kind: '美妆', sessions: '直播单链接', lb_fee: '130000+30%佣金'}
          ]
        },
        zc_total: null,//赞藏数（万）
        lb_time: null,//直播时间
        lb_case: null,//直播案例
        female_fans_ratio: null,//女粉比例
        avg_online: null,//平均在线
        cover: null,
        opened_star: 'N',
        opened_window: 'N',
        is_partner: 'N',
        opened_cart: 'N',
        cover_pc_url: '',
        //直播频次
        lf: '',
        //百应ID/UID
        by_uid: '',
        //直播业绩
        lsp: '',
        fans_state: [{
          'fans_count': null,
          'platform_code': 'douyin',
          'platform_name': '抖音粉丝数'
        }, {
          'fans_count': null,
          'platform_code': 'xiaohongshu',
          'platform_name': '小红书粉丝数'
        }, { 'fans_count': null, 'platform_code': 'kuaishou', 'platform_name': '快手粉丝数' }, {
          'fans_count': null,
          'platform_code': 'weibo',
          'platform_name': '微博粉丝数'
        }, { 'fans_count': null, 'platform_code': 'bilibili', 'platform_name': 'B站粉丝数' }, {
          'fans_count': null,
          'platform_code': 'weixin_vedio',
          'platform_name': '视频号粉丝数'
        }],//各个平台粉数数
        bs_case_rich: '',
        //红人标签
        kol_label: '',
        //红人肤质
        skin_type: ''
      }
    },
    addMultiRow() {
      if (!this.formData.multi)
        this.$set(this.formData, 'multi', [])

      this.formData.multi.push({ kind: '美妆', sessions: '直播单链接', lb_fee: '130000+30%佣金' })
    },
    delMultiRow(index) {
      if (!this.formData.multi)
        this.$set(this.formData, 'multi', [])

      this.formData.multi.splice(index, 1)
    },
    clearArtist() {
      if (this.$refs['refArtistSearch'])
        this.$refs['refArtistSearch'].clearSelect()
    },
    async selectArtist(selectInfo) {
      // console.log('si', selectInfo)
      if (selectInfo) {
        this.formData.nickname = selectInfo.nickname
        this.formData.artist_id = selectInfo.id
        this.formData.dept_id = selectInfo.group_id
        this.formData.dept_name = selectInfo.group_name
        //切换红人-期基本信息清空
        this.formData.home_page_link = ''
        this.formData.account_id = ''
        this.formData.agent_name = ''
        this.formData.city = ''
        this.formData.intro = ''
        this.formData.cover = 0
        this.formData.cover_pc_url = null
        //红人平台信息
        await this.getKolPlatformInfo()
        //日播达播不需要加载上月报价
        await this.copyLastInfo()
      } else {
        this.formData.nickname = null
        this.formData.artist_id = null
      }


    },
    //获取平台选择范围
    async getPlatformRange() {
      if (this.formData.artist_id) {
        let { info } = await this.$api.getArtistInfo(this.formData.artist_id)
        this.platformRange = []
        if (info.platforms) {
          let objs = info.platforms
          let codes = Object.keys(objs)
          for (let p in codes) {
            let code = codes[p]
            let val = objs[code]
            if (val === 'Y')
              this.platformRange.push(code)
          }
        }
      }
    },
    handleChangePlatform(val) {
      //若需要 platform_code 和 platform_name 替换成平台
      if (val)
        this.getPlatformSetting(true)
    },
    //获取平台配置信息
    async getPlatformSetting(needCalc) {
      // console.log(this.platformQuoteArr)
      //根据刊例主单的平台类型查询平台配置（日播和直播的平台是其他线上平台）
      if (this.platformType === 'LIVE' && this.formData.platform) {
        const platform = this.platforms.find(value => value.name === this.formData.platform)
        // console.log(platform)
        if (platform && platform.code) {
          this.formData.extra_platform_code = platform.code
          let { info } = await this.$api.getPlatformSettingInfoByPlatformCode(platform.code)
          // console.log(info.platform_quote)
          this.platformQuoteArr = info.platform_quote || []
          // this.$notify.info('报价类型同步XXX')
        } else {
          this.$notify.warning('所选平台报价配置信息获取异常')
        }
      } else {
        this.platformQuoteArr = this.setting.platform_quote || []
      }

      //平台的报价信息
      if (needCalc) {
        //添加刊例的时候直接组装报价录入表格
        this.calcPlatformQuote()
      }
    },
    async initFansState() {
      if (this.platformType === 'OFFLINE') {
        let fansState = []
        this.platforms.forEach((item) => {
          fansState.push({ platform_name: `${item.name}粉丝数`, platform_code: item.code, fans_count: null })
        })
        this.formData.fans_state = fansState
      }

    },
    calcPlatformQuote() {
      // 直播和日播不需要
      let platformQuoteArr = this.platformQuoteArr
      if (platformQuoteArr.length > 0) {
        //组装
        this.formData.current_quote = {
          single: [],
          multi: []
        }

        this.formData.next_quote = {
          single: [],
          multi: []
        }

        if (platformQuoteArr && platformQuoteArr.length > 0) {
          platformQuoteArr.forEach((item) => {
            this.formData.current_quote.single.push(Object.assign({}, item, { remark: '' }))
            this.formData.next_quote.single.push(Object.assign({}, item, { remark: '' }))
          })
        }
      }


    },
    refreshPlatformQuote() {
      if (!this.formData.platform_code) {
        this.$notify.warning(`请先选择平台置！！！`)
        return
      }
      //刷新报价类型
      this.$confirm('重载报价类型后，需要重写录入报价, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.getPlatformSetting(true)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })

    },
    async getKolPlatformInfo() {
      // this.$notify.info(`${this.platformInfo.code} - ${this.formData.artist_id}`)
      if (this.platformInfo.code && this.formData.artist_id) {
        this.formData.cover = null
        this.formData.cover_pc_url = null
        this.formData.home_page_link = null
        this.formData.account_id = null
        this.formData.agent_name = null
        this.formData.city = null
        this.loading = true
        let { info } = await this.$api.getArtistPlatformInfo({
          code: this.platformInfo.code,
          artist_id: this.formData.artist_id
        })

        this.formData.home_page_link = info.home_page_link
        this.formData.account_id = info.account_id
        this.formData.agent_name = info.agent_name
        this.formData.city = info.city
        this.formData.intro = info.intro
        this.formData.cover = info.cover
        this.formData.kol_label = info.kol_label
        this.formData.skin_type = info.skin_type
        this.formData.cover_pc_url = info.cover_pc_url
        this.loading = false
      }
      // else {
      //   this.$notify.warning(`${this.formData.platform_code}:${this.formData.artist_id}`)
      // }
    },
    async getCurrentMonthData() {
      this.formData.fans_total = null
      this.formData.last_release_date = null
      if (this.formData.platform_code && this.formData.artist_id && this.formData.year && this.formData.month) {
        let { fans_total, last_release_date } = await this.$api.getPublicationCurrentData({
          artist_id: this.formData.artist_id,
          platform_code: this.formData.platform_code,
          year: this.formData.year,
          month: this.formData.month
        })
        this.$nextTick(() => {
          this.formData.fans_total = fans_total
          this.formData.last_release_date = last_release_date
        })
      }
    },
    openUrl(href) {
      window.open(href, '_blank')
    },
    // 响应编辑按钮
    goToArtistDetail(id) {
      const { href } = this.$router.resolve({
        name: 'artist-edit',
        params: { artist_id: id }
      })
      // this.$router.push(this.$route.path + '/' + id + '/edit')
      window.open(href, '_blank')
    },
    //调整到品配置
    jumpToSettingPage(row) {
      let id = row.id
      const { href } = this.$router.resolve({
        name: 'platform_plc_set',
        params: { platform_id: id }
      })
      // this.$router.push(this.$route.path + '/' + id + '/edit')
      window.open(href, '_blank')
    },
    handleConfirm() {
      this.$refs['elForm'].validate(valid => {
        if (!valid) return
        this.onSubmit()
      })
    },
    async onSubmit() {
      this.loadingSb = true
      setTimeout(() => {
        this.loadingSb = false
      }, 1000)

      let extra_json = { ...this.formData }
      delete extra_json.extra_info
      delete extra_json.current_quote
      delete extra_json.next_quote
      delete extra_json.real_quote
      delete extra_json.bs_case
      delete extra_json.bs_case_rich

      extra_json['type'] = this.platformType
      let id = await this.$api.savePublicationItem(Object.assign(this.formData, { extra_info: extra_json }))
      if (id) {
        this.$notify.success('保存成功')
        this.loadingSb = false
        this.$emit('saved')
      }
    },
    async getInfo(id) {
      // this.$notify.info(`ID:${id}`)
      this.loadingForm = true
      let { info } = await this.$api.getPublicationItemInfo(id)
      if (info) {
        //表单数据
        this.formData = info
        //获取平台选择范围
        this.loadingForm = false
        //平台报价配置信息和额外字段
        await this.getPlatformSetting(false)
      }
    },
    async syncFromAdSystem() {
      this.loadingOrderTotal = true
      if (this.formData.platform_code && this.formData.artist_id && this.formData.year && this.formData.month) {
        let param = {
          artist_id: this.formData.artist_id,
          platform_code: this.formData.platform_code,
          year: this.formData.year,
          month: this.formData.month
        }

        let { total, code } = await this.$api.getPublicationOrderTotal(param)
        this.loadingOrderTotal = false
        if (code === 200) {
          if (total != undefined) {
            //同步自广告系统
            this.$confirm(`红人(KOL)【${this.formData.nickname}】的广告订单数为 ${total} (数据来自星广汇系统), 是否同步?`, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.$nextTick(() => {
                this.formData.order_total = total
              })
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消同步'
              })
            })
          }
        } else if (code === 404) {
          this.$confirm(`红人(KOL)【${this.formData.nickname}】未与星广汇系统匹配， 是否前往匹配?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.dialogArtistMatchVisible = true
            // this.goToArtistDetail(this.formData.artist_id)
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            })
          })
        }
      } else {
        this.$notify.warning('请先选择红人、平台、月份！！！')
        this.loadingOrderTotal = false
      }
    },
    delRow() {
      this.$confirm(`确定删除?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let info = await this.$api.deletePublicationItem(this.formData.id)
        if (info) {
          this.$notify.success('删除成功')
          this.$emit('deleted')
        }

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })

    },
    copyLastInfo() {
      //添加提报的时候同步上月信息：商务案例、当月价格（同步自上月的定价）
      if (!this.formData.id && this.formData.artist_id && this.platformInfo.code) {
        this.copyQuote()
      }
    },
    /**
     * 同步上月定价：不调价
     */
    copyLastPlatformQuote() {
      this.formData.next_quote = deepClone(this.formData.current_quote)
    },
    async copyQuote() {
      let { info } = await this.$api.getLastPublicationItem({
        artist_id: this.formData.artist_id,
        year: this.formData.year,
        month: this.formData.month,
        platform_code: this.platformInfo.code
      })
      if (info) {
        //同步商务案例
        if (info.bs_case) {
          this.formData.bs_case = info.bs_case || ''
          this.$notify.success('商务案例已同步')
        }
        //同步上月定价为本月价格
        if (info.real_quote) {
          this.formData.current_quote = deepClone(info.real_quote)
          this.$notify.success('报价已同步')
        } else {
          this.$notify.warning('上月未定价！')
        }
      } else {
        this.$notify.warning('上月未提报！')
      }
    },
    clearQuote() {
      this.copyQuote()
      //清空报价
      let dataType = this.getDataType(this.formData.next_quote)
      if (dataType === 'array') {
        this.formData.next_quote.forEach((item) => {
          item.val = null
          item.remark = null
        })
      } else {
        this.formData.next_quote.multi = []
        this.formData.next_quote.single.forEach((item) => {
          item.val = null
          item.remark = null
        })
      }
      this.msgSuccess('已清空下月预估报价')
    },
    getDataType(data) {
      if (data) {
        if (typeof data === 'object') {
          if (data instanceof Array) {
            return 'array'
          } else {
            return 'object'
          }
        }
      }
      return null
    },
    //各个平台粉数数量获取
    async getFansData() {
      this.loading = true
      if (this.formData.artist_id && this.formData.year && this.formData.month) {
        let { data_fans } = await this.$api.getFansData({
          artist_id: this.formData.artist_id,
          year: this.formData.year,
          month: this.formData.month
        })
        let fans_total = 0
        if (data_fans && data_fans.length > 0) {
          data_fans.forEach((item) => {
            let index = this.formData.fans_state.findIndex(value => value.platform_code === item.platform_code)
            if (index > -1) {
              // console.log(`${ index } item`, item)
              this.formData.fans_state[index]['fans_count'] = item.fans_count || 0
              fans_total += item.fans_count * 1
            }
          })
          this.formData.fans_total = fans_total
        } else {
          this.$notify.warning('平台粉丝信息为空')
        }
      }

      this.loading = false
    },
    //申请改价
    async applyEditPrice() {
      let { info } = await this.$api.applyEditPricePublicationItem({ id: this.formData.id })
      if (info)
        this.msgSuccess('已申请改价')
    },
    //修改保存-刊例报价
    async saveEdit() {
      this.editPrice = false
      //修改报价
      let data = {
        id: this.formData.id,
        current_quote: this.formData.current_quote,
        next_quote: this.formData.next_quote
      }
      let info = await this.$api.saveQuoteItem(data)
      if (info)
        this.msgSuccess('保存成功')

      this.$emit('updated')
    },
    //修改保存-刊例基本信息
    async saveBaseBtn() {
      this.editBase = false
      let extra_json = { ...this.formData }
      delete extra_json.extra_info
      delete extra_json.current_quote
      delete extra_json.next_quote
      delete extra_json.real_quote
      delete extra_json.bs_case
      delete extra_json.bs_case_rich
      delete extra_json.id

      extra_json.type = this.platformType
      //修改基本信息
      let data = Object.assign({}, this.formData, { extra_info: extra_json })
      delete data.current_quote
      delete data.next_quote
      delete data.real_quote
      let info = await this.$api.saveBasePublicationItem(data)
      if (info)
        this.msgSuccess('保存成功')

      this.$emit('updated')
    },
    async getPlatforms() {
      let { list } = await this.$api.getPlatforms()
      this.platforms = list || []
    },
    handleSelectPlatform(val) {
      if (val) {
        this.formData.platform_code = val.code
        this.formData.platform_name = val.name
        this.getKolPlatformInfo()
      }

    },
    // eslint-disable-next-line
    calcColName(field, title) {
      let columns = this.setting.columns_data
      let obj = columns.find(value => value.field === field)
      return obj ? obj.title : title
    },
    handleSyncLastMonth: async function() {
      const _this = this
      if (!this.formData.artist_id) {
        _this.$message.warning('请先选择红人')
        return
      }
      if (!this.formData.extra_platform_code) {
        _this.$message.warning('请先选择平台')
        return
      }
      _this.$confirm(`确认同步上月数据吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const dom = _this.$refs.BaseForm
        const nodes = dom.querySelectorAll('.el-form-item__label')
        const ext_fields = ['fans_total', 'order_total', 'next_order_total', 'last_release_date']
        const fields = []
        for (let i = 0; i < nodes.length; i++) {
          const tmp = nodes[i]
          const field = tmp.getAttribute('for') || false
          if (!ext_fields.includes(field) && field) {
            fields.push(field)
          }
        }
        const { artist_id, year, month, extra_platform_code } = _this.formData
        const { info } = await this.$api.getLastPublicationItem({ artist_id, year, month, extra_platform_code })
        fields.map(field => {
          _this.formData[field] = info ? info[field] : ''
        })
        _this.$message.success('已同步')
      }).catch(() => {

      })
    }
  }
}
</script>

<style scoped lang='scss'>
.box-panel {
  padding: 10px;
}

.box-panel .search-box {
  padding: 10px;
  border-radius: 8px;
  border: #f2f2f2 1px solid;
  margin-bottom: 8px;
}

.box-panel .base-box, .price-box {
  border: #f2f2f2 1px solid;
  padding: 10px;
  border-radius: 4px;
}

.flex-start {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: flex-start
}

.border-effect {
  border: #f2f2f2 1px solid;
  padding: 10px 0;
}

.sub-title {
  width: 200px;
  padding: 5px 10px;
  background: #ff3176;
  color: #f2f2f2;
  margin-bottom: 10px;
  border-radius: 10px;
  margin-left: 5px;
}

/*.price-box {*/
/*  border: #f2f2f2 1px solid;*/
/*  padding: 10px;*/
/*  border-radius: 4px;*/
/*}*/

.submit-btn-row {
  text-align: center;
  margin-top: 10px;
  border-top: #f2f2f2 1px solid;
  padding: 10px;
}

.link-btn {
  /*color: #ff3176 !important;*/
  font-size: 16px !important;
  /*background-color: #ff3176!important;*/
  /*border: 1px solid #ff3176!important;*/
}

.is-label {
  display: inline-block;
  font-weight: 600;
  text-align: right;
  margin-right: 5px;
  font-size: 0.8rem;
}

.intro, .bs_case {
  /**
  首行缩进
   */
  text-indent: 2em;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.fl-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
}

.box-kol, .box-info {
  border: #f2f2f2 1px solid;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
}

.sync-last-month {
  position: absolute;
  right: 24px;
  top: -10px;
  background: #fff;
  padding: 0 10px;
  cursor: pointer;
  color: #ff3176;

  i {
    margin-right: 4px;
  }
}
</style>
